import styled from 'styled-components';

export const MobileOnly = styled.div`
  display: block;
  @media only screen and (min-width: 40rem) {
    display: none;
  }
`;

export const DesktopOnly = styled.div`
  display: none;
  @media only screen and (min-width: 40rem) {
    display: block;
  }
`;
