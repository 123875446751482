/* eslint-disable default-case */
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from 'styled-components';
import { Flex } from 'rebass/styled-components';
import theme from '../../constants/theme';
import { useLocale } from '../../hooks';
import enMessages from '../../copy/copy-en.json';
import deMessages from '../../copy/copy-de.json';
import GlobalStyle from './GlobalStyle';
import Header from './Header';
import Footer from './Footer';

const Container = ({ children }) => {
  const locale = useLocale(['en', 'de']);
  const [messages, setMessages] = useState(enMessages);

  useEffect(() => {
    switch (locale) {
    case 'en':
      setMessages(enMessages);
      break;
    case 'de':
      setMessages(deMessages);
      break;
    }
  }, [locale, setMessages]);

  return (
    <div
      style={{
        margin: '0 auto',
        maxWidth: 960,
        minHeight: '100vh',
        padding: '0 1.0875rem',
      }}
    >
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Corporation",
              "description": "Tugboat Coding offers full-stack software contracting services, including MVP development for startups as well as architecting software for enterprise.",
              "url": "https://www.tugboatcoding.com",
              "name": "Tugboat Coding",
              "logo": "https://www.tugboatcoding.com/images/logo_lg.png",
              "sameAs": [
                "https://www.linkedin.com/company/tugboat-coding",
                "https://github.com/tugboatcoding"
              ],
              "contactPoint": {
                "@type": "ContactPoint",
                "email": "hello@tugboatcoding.com",
                "contactType": "Enquiries"
              }
            }
          `}
        </script>
      </Helmet>
      <GlobalStyle />
      <IntlProvider messages={messages} locale={locale}>
        <ThemeProvider theme={theme}>
          <Flex pt="1.5rem" pb="2rem" flexDirection="column" minHeight="100vh">
            <Header />
            <div style={{ flex: 1 }}>
              {children}
            </div>
            <Footer />
          </Flex>
        </ThemeProvider>
      </IntlProvider>
    </div>
  );
};

export default Container;
