export default {
  breakpoints: ['40em', '52em', '64em'],
  fontSizes: [
    12, 14, 16, 20, 24, 32, 48, 64,
  ],
  colors: {
    primary: '#0D47A1',
    default: '#cdcdcd',
    textGrey: '#8c8c8c',
    black: '#282828',
  },
  space: [
    0, 4, 8, 16, 32, 64, 128, 256,
  ],
  fonts: {
    body: 'Work Sans, Helvetica, sans-serif',
    heading: 'Raleway, Helvetica, sans-serif',
    monospace: 'Menlo, monospace',
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  shadows: {
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)',
  },
  buttons: {
    primary: {
      color: 'white',
      bg: 'primary',
    },
    outline: {
      color: 'primary',
      bg: 'white',
      border: '1px solid #0D47A1',
    },
  },
};
