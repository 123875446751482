import { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';

export const useLocale = (locales = ['en']) => {
  const location = useLocation();
  const [locale, setLocale] = useState(locales[0]);
  useEffect(() => {
    const matchingLocale = locales.find((l) => (
      location.pathname.match(new RegExp(`^/${l || ''}`))
    ));
    if (matchingLocale) {
      setLocale(matchingLocale);
    } else {
      setLocale(locales[0]);
    }
  }, [locale, locales, location, setLocale]);
  return locale;
};
