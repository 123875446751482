import styled from 'styled-components';

const Clickable = styled.div`
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

export default Clickable;
