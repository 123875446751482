import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Link } from 'gatsby';
import { Box, Flex } from 'rebass';
import LogoLinkedin from 'react-ionicons/lib/LogoLinkedin';
import LogoGithub from 'react-ionicons/lib/LogoGithub';
import Clickable from './Clickable';
import { useLocale } from '../../hooks';

const BaseFooter = styled.footer`
  font-size: 12px;
  color: ${({ theme: { colors } }) => colors.textGrey};
`;

const Footer = () => {
  const locale = useLocale(['en', 'de']);
  const pathPrefix = locale === 'en' ? '' : '/de';

  return (
    <BaseFooter>
      <Box pt="72px">
        <Flex flexDirection={['column', 'row']} justifyContent={['flex-start', 'space-between']}>
          <Flex flexDirection="row" alignItems="center">
            <Box pr="10px">
              <Link to={locale === 'en' ? '/' : '/de'}><FormattedMessage id="global.footer.links.home" /></Link>
            </Box>
            <Box pr="10px">
              <Link to={`${pathPrefix}/about`}><FormattedMessage id="global.footer.links.about" /></Link>
            </Box>
            <Box pr="10px">
              <Link to={`${pathPrefix}/our-work`}><FormattedMessage id="global.footer.links.ourwork" /></Link>
            </Box>
            <Link to={`${pathPrefix}/contact`}><FormattedMessage id="global.footer.links.contact" /></Link>
          </Flex>
          <Flex flexDirection="row" alignItems="center" mt={['8px', '0px']}>
            <Box pr="16px">
              Follow us:
            </Box>
            <Box pr="10px">
              <Clickable>
                <a href="https://github.com/tugboatcoding" target="_blank" rel="noreferrer">
                  <Flex alignItems="center">
                    <LogoGithub fontSize="24px" color="#0D47A1" />
                  </Flex>
                </a>
              </Clickable>
            </Box>
            <Clickable>
              <a href="https://www.linkedin.com/company/tugboat-coding" target="_blank" rel="noreferrer">
                <Flex alignItems="center">
                  <LogoLinkedin fontSize="24px" color="#0D47A1" />
                </Flex>
              </a>
            </Clickable>
          </Flex>
        </Flex>
        <Box mt="8px">
          © {new Date().getFullYear()} Tugboat Coding Ltd.
          Registered in England and Wales. Company number 11118743.
        </Box>
      </Box>
    </BaseFooter>
  );
};

export default Footer;
