import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { Box, Flex } from 'rebass';
import logo from '../../images/logo.svg';
import { MobileOnly, DesktopOnly } from './media';
import { useLocale } from '../../hooks';
import logoWithText from '../../images/logo_with_text.svg';

const DontWrap = styled.span`
  white-space: nowrap;
`;

const Header = () => {
  const locale = useLocale(['en', 'de']);
  const pathPrefix = locale === 'en' ? '' : '/de';

  return (
    <header
      style={{
        marginBottom: '1.45rem',
      }}
    >
      <Flex flexDirection="row" alignItems="center" justifyContent="space-between">
        <Link
          to={locale === 'en' ? '/' : '/de'}
          style={{
            color: 'hsla(0, 0%, 0%, 0.8)',
            textDecoration: 'none',
          }}
        >
          <MobileOnly>
            <img alt="Logo" src={logo} width="48px" />
          </MobileOnly>
          <DesktopOnly>
            <img alt="Tugboat Coding" src={logoWithText} width="240px" />
          </DesktopOnly>
        </Link>
        <Flex flexDirection="row" alignItems="center">
          <Box pr="16px">
            <DontWrap>
              <Link to={`${pathPrefix}/about`}>
                <FormattedMessage id="global.nav.links.about" />
              </Link>
            </DontWrap>
          </Box>
          <Box pr="16px">
            <DontWrap>
              <Link to={`${pathPrefix}/our-work`}>
                <FormattedMessage id="global.nav.links.ourwork" />
              </Link>
            </DontWrap>
          </Box>
          <DontWrap>
            <Link to={`${pathPrefix}/contact`}>
              <FormattedMessage id="global.nav.links.contact" />
            </Link>
          </DontWrap>
        </Flex>
      </Flex>
    </header>
  );
};

export default Header;
